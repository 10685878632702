import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ 
  siteTitle,
  cv  
}) => (
  <header>
    <div class="wrap">
      <h1>{siteTitle}</h1>
      <nav>
        <Link to="/" activeClassName="active" data-hover="About">About</Link>
        <Link to="/research" activeClassName="active" data-hover="Research">Research</Link>
        <Link to="/contact" activeClassName="active" data-hover="Contact">Contact</Link>
        <a href={ cv } data-hover="CV">CV</a>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
