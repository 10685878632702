/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.css"

class Layout extends React.Component {
  
  constructor() {
    super();
    this.state = {
      isLong: false
    }
  }
  
  updateDimensions() {
    if(document.getElementsByClassName("content")[0].offsetHeight > document.getElementsByTagName("main")[0].offsetHeight) {
      this.setState({ isLong: true });
    } else {
      this.setState({ isLong: false });
    }
  }

  pictureMargin() {
    document.getElementsByClassName("profile-img")[0].setAttribute("style", "transform: translateY(" + String(window.scrollY) + "px)");
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("scroll", this.pictureMargin.bind(this));
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <>
        <Header siteTitle={ this.props.fullName } cv={ this.props.cv } />
        <main>
          <div class="wrap">
            <div class="profile">
              <img class="profile-img" alt={"Picture of " + this.props.fullName } src={ this.props.profile } />
            </div>
            <div class={this.state.isLong ? 'content long' : 'content'}>{this.props.children}</div>
          </div>
        </main>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
